.contact{
    background-color: #191919;
}
h1.hire__text {
    font-size: 60px;
}
p.hire__text{
    font-size: 18px;
    line-height: 30px;
    color: rgba(29,29,36,.75);
    font-weight: 400;
}
input.contact{
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0,0,0,.1);
    border-radius: 5px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    height: 50px;
    line-height: 46px;
    margin: 20px 0px;
    outline: none;
    color: #fff;
    font-size: 15px;
    letter-spacing: .1px;
    background: none;
    border: 2px solid hsla(0,0%,100%,.2);
}
.contact__img{
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
}
textarea#message{
    height: 120px;
    background: none;
    padding: 0 20px;
    width: 100%;
    line-height: 46px;
    color: #fff;
    font-size: 15px;
    letter-spacing: .1px;
    border: 2px solid hsla(0,0%,100%,.2);
}
button.btn.contact.pointer {
    margin: 20px 0px;
}