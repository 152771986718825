.logo {
  width: 30%;
  object-fit: contain;
}
.home {
  position: relative;
  width: 100%;
}
.line {
  height: 2px;
  width: 100%;
  background-color: #fff;
}

.loader {
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: #2fa463;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-clip {
  position: absolute;
  width: 100vw;
  height: 33.3vh;
  overflow: clip;
  z-index: 1000;
}

.clip-top {
  display: block;
  top: 0;
  clip-path: inset(0 0 0 0);
  background: #000;
}
.clip-bottom {
  bottom: 0;
  clip-path: inset(0 0 0 0);
  background: #000;
}
.clip-center {
  overflow: hidden;
  position: relative;
  top: 33.3vh;
  height: 33.3vh;
  width: 100vw;
  background: #fff;
}

.marquee {
  position: absolute;
  top: 200%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 200vw;
  color: #fff;
  mix-blend-mode: difference;
}

.marquee-container {
  width: 100%;
  padding-top: 0.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
