.project {
  /* background: linear-gradient(
    to bottom,
    #030303b4,
    #00000019 60%,
    #08080816 40%,
    #ffffff00
  ); */
}
img.project__img {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  transition: 0.4s;
  opacity: 0.7;
  position: relative;
}
img.project__img::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 1;
}
.legend__container {
  width: 70%;
  margin: 30px auto;
}
.mask__effect {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2fa463;
  border-radius: 10px;
  transition: 0.4s;
  opacity: 0.3;
  display: none;
}
.project__box:hover .mask__effect {
  display: inline;
  transform: scale(1.1);
}
.project__box:hover img.project__img {
  transform: scale(1.1);
}
.project__meta.absolute {
  top: 0%;
  left: 0%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 10px;
  width: 100%;
}
h5.project__text {
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 20px;
}
h4.project__text {
  font-size: 22px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  padding-bottom: 20px;
}
a.project__btn {
  padding: 12px 35px;
  border: 2px solid #ffffff87;
  border-radius: 5px;
  transition: 0.4s ease;
}
a.project__btn:hover {
  background-color: #2fa463;
  border: 2px solid #2fa463;
}
button.btn {
  padding: 15px 20px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  border: 2px solid #2fa463;
  background-color: #2fa463;
  color: #fff !important;
  text-transform: uppercase;
  /* letter-spacing: 2px; */
  transition: 0.4s all ease;
}
button.view__more:hover {
  background-color: #fff;
  border: 2px solid #2fa463;
  color: #2fa463 !important;
}
/* RESPONSIVE */
@media only screen and (max-width: 1024px) {
  h5.project__text {
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 20px;
  }
  h4.project__text {
    font-size: 18px;
  }
}

.containerSlider {
  max-width: 124rem;
  padding: 0rem 0;
  margin: 0 auto;
}

.swiper_container {
  height: 54rem;
  padding: 2rem 0 !important;
  position: relative;
}

.swiper-slide {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 1px 1px 5px 6px #00000019;
  width: 37rem !important;
  height: 42rem !important;
  position: relative;
}

@media (max-width: 400px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 19rem !important;
    height: 36rem !important;
  }
  /* .swiper-slide img {
    width: 18rem !important;
    height: 36rem !important;
  } */
}
@media (max-width: 770px) and (min-width: 401px) {
  .swiper-slide {
    width: 23rem !important;
    height: 38rem !important;
  }
  /* .swiper-slide img {
    width: 23rem !important;
    height: 36rem !important;
  } */
}

.swiper-slide img#bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .legend__container {
    width: 90%;
    margin: 30px auto;
    p {
      font-size: 15px !important;
    }
  }
  h1.heading {
    font-size: 45px !important;
    text-align: center;
  }
  h1.motto__heading.center {
    padding: 0 20px;
  }
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: #fff;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative !important;
  width: 15rem !important;
  bottom: 4rem !important;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #000;
}
