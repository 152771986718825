@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pinyon+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}
body {
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: sans-serif;
}
html {
  scroll-behavior: smooth;
}
button {
  font-family: "Poppins";
}
@font-face {
  font-family: "PoppinsExtraBold";
  src: local("PoppinsExtraBold"),
    url(/static/media/Poppins-ExtraBold.c3e08dd1.eot) format("eot"),
    url(/static/media/Poppins-ExtraBold.01849ee0.ttf) format("ttf"),
    url(/static/media/Poppins-ExtraBold.743e09a2.woff) format("woff");
  font-weight: 700;
}
a {
  text-decoration: none;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
span {
  color: #000;
  font-family: Roboto, sans-serif;
}
li {
  overflow: hidden;
}
span {
  display: block;
}
p {
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.white {
  color: #000 !important;
}
.black {
  color: #fff !important;
}
.underline {
  text-decoration: underline;
}
.bright {
  color: #2fa463;
}
.d__flex {
  display: flex;
}
.align__items__center {
  align-items: center;
}
.justify__content__center {
  justify-content: center;
}
.justify__content__space__between {
  justify-content: space-between;
}
.mx__15 {
  margin: 0px 15px;
}
.mx__45 {
  margin: 0px 45px;
}
.mb__25 {
  margin-bottom: 25px !important;
}
.px__30 {
  padding: 0px 30px;
}
.pxy__30 {
  padding: 30px;
}
.container {
  width: 100%;
  margin: 0px auto;
}
.container.service {
  width: 100%;
  margin: 45px auto 0;
}
.container.about {
  max-width: 1400px;
  margin: auto;
}
.reverse {
}
.container.home {
  width: 100%;
  margin: 0px auto;
  min-height: 75vh;
  background: linear-gradient(
    to right,
    #030303b4,
    #00000019 60%,
    #08080816 40%,
    #ffffff00
  );
}
.pz__10 {
  padding: 10px 0px;
}
.pz__5 {
  padding: 5px 0px;
}
.sweet {
  color: #2fa463;
}
.center {
  text-align: center !important;
}
.left {
  text-align: left !important;
}
.row {
  display: flex;
  width: 90%;
  margin: 20px auto;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: stretch;
  justify-content: center;
  min-height: 100%;
  grid-gap: 30px;
  gap: 30px;
}
.row-service {
  display: flex;
  width: 97%;
  margin: 20px auto;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: stretch;
  justify-content: center;
  min-height: 100%;
  grid-gap: 30px;
  gap: 30px;
}
.grid__2 {
  display: grid;
  width: 90%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  margin: 20px auto;
}
.grid-col__2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.col__2 {
  flex: 1 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}
.col__3 {
  max-width: 33.333333%;
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}
.component__space {
  padding: 30px 0px;
}
.pointer {
  cursor: pointer;
}
svg {
  stroke-width: 1 !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
strong {
  /* color: #2fa463; */
  font-weight: 600;
}
div.bright {
  display: inline;
  color: #2fa463;
  font-size: 45px;
}
input {
  color: rgba(198, 201, 216, 0.75);
}
button.btn {
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  border: 2px solid #2fa463;
  background-color: #2fa463;
  color: #000 !important;
  text-transform: uppercase;
  /* letter-spacing: 2px; */
  transition: 0.4s all ease;
  height: 50px;
  width: 180px;
  margin: 0px 0px;
}
.p__color {
  color: rgba(0, 0, 0, 0.75);
  font-size: 18px;
  line-height: 30px;
  font-family: Poppins, sans-serif;
  margin-bottom: 15px;
}
button.about.btn.pointer:hover {
  background: #f9004fb9;
  color: #fff !important;
}

.button__primary {
  padding: 11px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: none;
  /* background: linear-gradient(to left, #e66465, #ee3c18); */
  /* old-color: f9004d */
  background-color: #2fa463;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  text-shadow: 1px 1px #0000008e;
  /* letter-spacing: 1.5px; */
  max-width: 350px;
  width: 290px !important;
  margin-top: 30px;
  cursor: pointer;
}
/* RESPONSIVE */
@media only screen and (max-width: 768px) {
  .col__2 {
    max-width: 100%;
  }
  .grid__2 {
    width: 90%;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    gap: 30px;
    margin: 20px auto;
  }
}
@media only screen and (max-width: 768px) {
  .row.reverse {
    flex-direction: column-reverse;
  }
  .row {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1120px) {
  .row-service {
    flex-direction: column;
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .col__3 {
    max-width: 50%;
  }
}
@media only screen and (max-width: 425px) {
  .col__3 {
    max-width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  span.copyright {
    font-size: 13px !important;
  }
  .footer.d__flex.align__items__center.justify__content__center.pz-10 {
    flex-wrap: wrap;
  }
}

@font-face {
  font-family: "ProtestRegular";
  src: local("ProtestRegular"),
    url(/static/media/ProtestRiot-Regular.0b940d3a.eot) format("eot"),
    url(/static/media/ProtestRiot-Regular.8de441de.ttf) format("ttf"),
    url(/static/media/ProtestRiot-Regular.8a22807a.woff) format("woff");
  font-weight: 500;
}

.about {
  background: #fff;
}
img.about__img {
  border-radius: 10px;
  width: 100%;
  max-width: 590px;
  margin-left: auto;
}
h1.motto__heading {
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "PoppinsExtraBold" !important;
  text-align: start;
  font-size: 45px;
}
h1.about__heading {
  margin-bottom: 10px;
  color: #2fa463;
  font-weight: 600;
  text-align: start;
  font-size: 40px;
}
button.about.btn.pointer {
  text-transform: capitalize;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
  height: 55px;
  font-size: 18px;
  font-weight: 600;
  text-shadow: 1px 1px #00000088;
  transition: 0.4s ease;
}
.about__button {
  padding: 20px 0px;
}
a.bottom__to__top {
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 3%;
  right: 2%;
  background-color: #2fa463;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  transition: 0.4s all ease;
  z-index: 1000;
}
a.bottom__to__top.active {
  display: flex;
}
div.row.about {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
/* RESPONSIVE */
@media only screen and (max-width: 1024px) {
  .p__color {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 425px) {
  button.about.btn.pointer {
    text-transform: capitalize;
    width: 100%;
    height: 50px;
    font-size: 18px;
    transition: 0.4s ease;
  }
  div.about__button {
    flex-direction: column;
  }
  h1.motto__heading {
    margin-bottom: 10px;
    font-weight: 500;
    font-family: "PoppinsExtraBold" !important;
    text-align: start;
    font-size: 35px;
  }
}

.blog__meta{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Blog__meta.absolute {
    top: 0%;
    padding: 0px 20px;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
}
a.blog.project__btn {
    margin-bottom: -50px;
    transition: .4s all ease;
}
.Blog__meta:hover a.blog.project__btn{
    margin-bottom: 40px;
}
.contact{
    background-color: #191919;
}
h1.hire__text {
    font-size: 60px;
}
p.hire__text{
    font-size: 18px;
    line-height: 30px;
    color: rgba(29,29,36,.75);
    font-weight: 400;
}
input.contact{
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0,0,0,.1);
    border-radius: 5px;
    transition: all .3s ease;
    height: 50px;
    line-height: 46px;
    margin: 20px 0px;
    outline: none;
    color: #fff;
    font-size: 15px;
    letter-spacing: .1px;
    background: none;
    border: 2px solid hsla(0,0%,100%,.2);
}
.contact__img{
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
}
textarea#message{
    height: 120px;
    background: none;
    padding: 0 20px;
    width: 100%;
    line-height: 46px;
    color: #fff;
    font-size: 15px;
    letter-spacing: .1px;
    border: 2px solid hsla(0,0%,100%,.2);
}
button.btn.contact.pointer {
    margin: 20px 0px;
}
.home__bg {
  background-image: -webkit-image-set(
    url(/static/media/hero-bg-1x.27dae67f.webp) 1x,
    url(/static/media/hero-bg-2x.046adf5c.webp) 2x
  );
  background-image: image-set(
    url(/static/media/hero-bg-1x.27dae67f.webp) 1x,
    url(/static/media/hero-bg-2x.046adf5c.webp) 2x
  );
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

li.nav__items {
  font-size: 19px;
  font-weight: 300;
  padding-bottom: 5px;
  color: #fff;
}
.collapsed {
  /* margin-bottom: -5px;
  padding-bottom: 0px !important; */
}
.collapsed-toggle {
}
ul.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  max-width: 600px;
}
.home__content {
  padding: 65px 0px 125px;
  /* width: 60%;
  max-width: 500px;
  display: flex;
  height: 100%;
  justify-content: center;
  gap: 30px; */
}
.home__meta {
  padding: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 900px;
  display: flex;
  height: 100%;
  margin-left: 10%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
h1.home__text {
  font-size: 58px;
  color: #fff;
  line-height: 120%;
  font-family: "PoppinsExtraBold" !important;
  margin-top: -20px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-transform: uppercase;
  /* color: #2fa463; */
  text-align: center;
  text-shadow: 1px 1px #000;
}
h2.home__text {
  font-size: 22px;
  line-height: 120%;
  font-weight: 400;
  color: #2fa463;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  /* margin-top: 10px; */
  text-shadow: 1px 1px #000;
}
h1.home__text.bright {
  color: #2fa463;
  font-weight: 500;
  text-shadow: 1px 1px #000;
}
h3.home__text {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  line-height: 130%;
}
h4.home__text {
  font-size: 50px;
  font-weight: 800;
  line-height: 40px;
}
.header {
  transition: 0.4s all ease;
  background: linear-gradient(
    to right,
    #030303b4,
    #00000019 60%,
    #08080816 40%,
    #ffffff00
  );
}
.pale {
  color: #fff !important;
  font-size: min(3vw, 26px) !important;
  margin-top: 10px;
  font-weight: 400 !important;
}
.header.active {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 8px 30px 0;
  background-color: #191919bc;
}
.toggle__menu {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  width: 50px;
  margin-top: -10px;
}
.sideNavbar {
  z-index: 100;
}
ul.sidebar.d__flex {
  flex-wrap: wrap;
  height: 100%;
  position: fixed;
  right: 0%;
  background: #010101ec;
  justify-content: center;
  width: 350px;
  top: 0%;
  flex-direction: column;
}
.navigation {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: -7px;
}

li.sideNavbar {
  padding: 25px 30px;
}
li.sideNavbar a {
  font-size: 20px;
  font-weight: 500;
}
.highlight {
  background-color: rgba(49, 147, 36, 0.482);
  padding: 0 15px;
}
/* RESPONSIVE */
@media only screen and (max-width: 890px) {
  .header {
    justify-content: space-between;
  }
  .navigation {
    display: none;
  }
  h2 {
    font-size: 16px !important;
    text-shadow: 1px 1px #00000083;
    font-weight: 400;
  }
  svg.bi.bi-justify.white.pointer {
    width: 30px;
    height: 30px;
  }
  h1.home__text {
    font-size: 38px;
  }
  h1.home__text.pale {
    font-size: 27px !important;
    margin-top: 25px;
  }
  .home__bg {
    background-position: 60%;
  }
}
@media only screen and (max-width: 425px) {
  h2.home__text.pz__10 {
    font-size: 32px;
  }
  h3.sweet.pz__10 {
    font-size: 40px;
  }
  .home__meta {
    margin-left: 0;
    margin: auto;
    align-items: center;
  }
  h4.home__text {
    font-size: 45px;
  }
}
.header.active .navigation ul li.nav__items {
  color: #fff;
  font-weight: 400;
}
.logo img {
  max-width: 220px;
}
.logo {
  cursor: pointer;
}

.logo {
  width: 30%;
  object-fit: contain;
}
.home {
  position: relative;
  width: 100%;
}
.line {
  height: 2px;
  width: 100%;
  background-color: #fff;
}

.loader {
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: #2fa463;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-clip {
  position: absolute;
  width: 100vw;
  height: 33.3vh;
  overflow: clip;
  z-index: 1000;
}

.clip-top {
  display: block;
  top: 0;
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  background: #000;
}
.clip-bottom {
  bottom: 0;
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  background: #000;
}
.clip-center {
  overflow: hidden;
  position: relative;
  top: 33.3vh;
  height: 33.3vh;
  width: 100vw;
  background: #fff;
}

.marquee {
  position: absolute;
  top: 200%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  width: 200vw;
  color: #fff;
  mix-blend-mode: difference;
}

.marquee-container {
  width: 100%;
  padding-top: 0.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project {
  /* background: linear-gradient(
    to bottom,
    #030303b4,
    #00000019 60%,
    #08080816 40%,
    #ffffff00
  ); */
}
img.project__img {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  transition: 0.4s;
  opacity: 0.7;
  position: relative;
}
img.project__img::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 1;
}
.legend__container {
  width: 70%;
  margin: 30px auto;
}
.mask__effect {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2fa463;
  border-radius: 10px;
  transition: 0.4s;
  opacity: 0.3;
  display: none;
}
.project__box:hover .mask__effect {
  display: inline;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.project__box:hover img.project__img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.project__meta.absolute {
  top: 0%;
  left: 0%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 10px;
  width: 100%;
}
h5.project__text {
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 20px;
}
h4.project__text {
  font-size: 22px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  padding-bottom: 20px;
}
a.project__btn {
  padding: 12px 35px;
  border: 2px solid #ffffff87;
  border-radius: 5px;
  transition: 0.4s ease;
}
a.project__btn:hover {
  background-color: #2fa463;
  border: 2px solid #2fa463;
}
button.btn {
  padding: 15px 20px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  border: 2px solid #2fa463;
  background-color: #2fa463;
  color: #fff !important;
  text-transform: uppercase;
  /* letter-spacing: 2px; */
  transition: 0.4s all ease;
}
button.view__more:hover {
  background-color: #fff;
  border: 2px solid #2fa463;
  color: #2fa463 !important;
}
/* RESPONSIVE */
@media only screen and (max-width: 1024px) {
  h5.project__text {
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 20px;
  }
  h4.project__text {
    font-size: 18px;
  }
}

.containerSlider {
  max-width: 124rem;
  padding: 0rem 0;
  margin: 0 auto;
}

.swiper_container {
  height: 54rem;
  padding: 2rem 0 !important;
  position: relative;
}

.swiper-slide {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 1px 1px 5px 6px #00000019;
  width: 37rem !important;
  height: 42rem !important;
  position: relative;
}

@media (max-width: 400px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 19rem !important;
    height: 36rem !important;
  }
  /* .swiper-slide img {
    width: 18rem !important;
    height: 36rem !important;
  } */
}
@media (max-width: 770px) and (min-width: 401px) {
  .swiper-slide {
    width: 23rem !important;
    height: 38rem !important;
  }
  /* .swiper-slide img {
    width: 23rem !important;
    height: 36rem !important;
  } */
}

.swiper-slide img#bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  -webkit-transform: translateX(-58%) !important;
          transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .legend__container {
    width: 90%;
    margin: 30px auto;
    p {
      font-size: 15px !important;
    }
  }
  h1.heading {
    font-size: 45px !important;
    text-align: center;
  }
  h1.motto__heading.center {
    padding: 0 20px;
  }
  .slider-controler .swiper-button-next {
    left: 70% !important;
    -webkit-transform: translateX(-70%) !important;
            transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    -webkit-transform: translateX(-80%) !important;
            transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    -webkit-transform: translateX(-30%) !important;
            transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    -webkit-transform: translateX(-20%) !important;
            transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: #fff;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  -webkit-transform: translateX(-42%);
          transform: translateX(-42%);
  -webkit-filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
          filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative !important;
  width: 15rem !important;
  bottom: 4rem !important;
}

.swiper-pagination .swiper-pagination-bullet {
  -webkit-filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
          filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #000;
}

h1.heading {
  font-size: 60px;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}
p.heading.p__color {
  margin-bottom: 0;
  text-align: center;
  line-height: 1.2;
  display: block;
  width: 80%;
  margin: auto;
}
.heading__services {
  font-size: 25px;
  margin-bottom: 20px;
}
.inline__link {
  display: inline;
  text-decoration: underline;
}
.service__box {
  border-radius: 10px;
  width: 100%;
  background-color: #212020ae;
  padding: 55px;
  transition: 0.4s ease;
  height: 100%;
  z-index: 5;
}
.retreat__box {
  border-radius: 10px;
  width: 100%;
  padding: 55px;
  transition: 0.4s ease;
  height: 100%;
  z-index: 5;
}
.service__box:hover {
  -webkit-transform: scale(5px);
          transform: scale(5px);
  background-color: #000000ae;
}
.service__box:hover .icon {
  color: #fff;
}
.service__box:hover p.service__text.p__color {
  color: #fff !important;
}

.icon {
  font-size: 54px;
  font-weight: 400;
  margin-bottom: 23px;
  display: inline-flex;
  color: #2fa463;
}
p.service__text.p__color {
  color: #ffffffb0 !important;
  margin-bottom: 0;
}
h1.service__text {
  font-size: 25px;
  font-family: Poppins, sans-serif;
  padding: 10px 0px;
  color: #fff !important;
  color: #fff !important;
}
div.service {
  border-radius: 10px;
  min-height: 370px;
  cursor: pointer;
}
div.service img.cover {
  position: absolute;
  /* top: -20%;
  bottom: 0;
  left: 0;
  right: 0; */
  z-index: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 10px;
}
div.service img.contain {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5%;
  right: 0;
  z-index: 0;
  object-fit: contain;
  height: 100%;
  opacity: 1;
  border-radius: 10px;
}
button.service {
  width: 100%;
  margin: 20px auto;
}
img.retreat {
  width: 100%;
  margin: 10px auto;
  object-fit: contain;
  max-width: 800px;
  border-radius: 10px;
}
/* RESPONSIVE */
@media only screen and (max-width: 1024px) {
  .service__box {
    padding: 32px;
    min-height: 400px;
  }
  div.service img.contain {
    left: -20%;
  }
}

.mySwiperRetreat {
  width: 100%;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.swiper-slide.styled {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  object-position: bottom;
}

.swiper-button-prev {
  background-color: #ffffffae;
  width: 50px;
  height: 70px;
  margin-left: -5px;
}
.swiper-button-next {
  background-color: #ffffffae;
  width: 50px;
  height: 70px;
  margin-right: -10px;
}
/*
.swiper-slide {
  width: 33% !important;
}

.swiper-slide:nth-child(2n) {
  width: 40% !important;
}

.swiper-slide:nth-child(3n) {
  width: 30% !important;
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.mySwiperRetreat {
  width: 100%;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.swiper-slide.styled {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  object-position: bottom;
}

.swiper-button-prev {
  background-color: #ffffffae;
  width: 50px;
  height: 70px;
  margin-left: -5px;
}
.swiper-button-next {
  background-color: #ffffffae;
  width: 50px;
  height: 70px;
  margin-right: -10px;
}
/*
.swiper-slide {
  width: 33% !important;
}

.swiper-slide:nth-child(2n) {
  width: 40% !important;
}

.swiper-slide:nth-child(3n) {
  width: 30% !important;
} */



